import {
  minLength,
  maxLength,
  helpers,
  alphaNum
} from 'vuelidate/lib/validators';

const cosmosParamLower = 'cosmos';
const cosmosParamUpper = 'COSMOS';
const cosmos = (param) => {
  let isValid = false;
  let helperValid = !helpers.req(param);

  helperValid || (
    param.indexOf(cosmosParamLower) === 0 ||
    param.indexOf(cosmosParamUpper) === 0
  ) ? (isValid = true) : (isValid = false)

  return isValid;
}

export default {
  validations: {
    cosmosReceiveAddress: {
      // minLength: minLength(42),
      // maxLength: maxLength(42),
      alphaNum,
      cosmos,
    },
  },
  computed: {
    isCosmosRecieveAddressError() {
      return (
        (this.$v.cosmosReceiveAddress.$dirty && this.$v.cosmosReceiveAddress.$invalid) ||
        !this.$v.cosmosReceiveAddress.alphaNum || !this.$v.cosmosReceiveAddress.cosmos
      );
    },
    cosmosRecieveAddressErrorText() {
      if (!this.$v.cosmosReceiveAddress.alphaNum) {
        return 'Recieve Address must contain only numbers and latin characters';
      }
      if (!this.$v.cosmosReceiveAddress.cosmos) {
        return 'Recieve Address must be COSMOS token';
      }
      // if (!this.$v.cosmosReceiveAddress.minLength || !this.$v.cosmosReceiveAddress.maxLength) {
      //   return 'Recieve Address must be exactly 42 characters';
      // }
    },
  },
};