import { SigningCosmosClient } from '@cosmjs/launchpad';
import converter from 'convert-bech32-address';

export default {
  methods: {
    async getKeplrAddress(tokenName) {
      if (!window.keplr) {
        window.open('https://www.keplr.app/download');
      } else {
        const chainId = process.env.VUE_APP_COSMOS_NETWORK_ID;

        await window.keplr.enable(chainId);

        const offlineSigner = window.keplr.getOfflineSigner(chainId);
        const accounts = await offlineSigner.getAccounts();
        const account = accounts[0];

        const address =
          tokenName === 'source'
            ? converter.lookup(account.address, 'source')
            : account.address;

        return address;
      }
    },
  },
};
