import Web3 from 'web3';
import axios from 'axios';
const Axios = axios.create();

const web3 = new Web3(Web3.givenProvider);
const dappURL = `https://metamask.app.link/dapp/${window.location.hostname}/`;

import bscContractABI from '@/mixins/metamask/bsc_contract_abi.json';
import srcxContractABI from '@/mixins/metamask/srcx_contract_abi.json';

const contractSrcxAddress = '0x454B90716a9435E7161a9AEa5Cf00e0aCBE565aE';
const contractBscAddress = '0x7c512219e264272b16685E22822eAADeA730F6bf';

let srcxAabi = JSON.stringify(srcxContractABI);
srcxAabi = JSON.parse(srcxAabi);
const SRCXContract = new web3.eth.Contract(srcxAabi, contractSrcxAddress);

let bscAabi = JSON.stringify(bscContractABI);
bscAabi = JSON.parse(bscAabi);
const BSCContract = new web3.eth.Contract(bscAabi, contractBscAddress);

export default {
  methods: {
    async getMetamaskAddress() {
      if (typeof window.ethereum !== 'undefined') {
        const accounts = await ethereum.request({
          method: 'eth_requestAccounts',
        });
        console.log('accounts', accounts);
        const account = accounts[0];

        return account;
      } else if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        window.open(dappURL);
      } else {
        window.open('https://metamask.io/download/');
        return 'not installed';
      }
    },
    async getSignature(message, address) {
      try {
        const from = address;
        const msg = message;
        const signature = await ethereum.request({
          method: 'personal_sign',
          params: [msg, from, 'Example password'],
        });
        console.log(signature);
        return signature;
      } catch (err) {
        console.error(err);
      }
    },
    async getBallance(address) {
      // let address = await this.getMetamaskAddress();
      try {
        const getBalance = await web3.eth.getBalance(address);
        const ethBalance = web3.utils.fromWei(getBalance, 'ether');
        return ethBalance;
      } catch (err) {
        console.error(err);
      }
    },
    async getNetwork() {
      let network = await web3.eth.net.getId();
      return network;
    },
    async connectWallet() {
      let address = await this.getMetamaskAddress();
      // console.log(address);
      if (address !== 'not installed') {
        await this.$store.dispatch('messageForSignIn', {
          eth_address: address,
        });
      }
      let network = await this.getNetwork();
      if (network != process.env.VUE_APP_ETH_NETWORK) {
        this.$store.commit('modal/SET_MODAL', {
          state: true,
          name: 'wrong-network',
        });
        return;
      }
      // console.log(store.getters.getMessageForSignIn);
      let signature;
      if (this.$store.getters.getMessageForSignIn) {
        signature = await this.getSignature(
          this.$store.getters.getMessageForSignIn,
          address
        );
        // console.log(signature);
      }
      let payload = { signature, eth_address: address };
      this.$route.query.referral_code
        ? (payload.referral_code = this.$route.query.referral_code)
        : null;
      if (signature) {
        await this.$store.dispatch('signIn', payload);
      }
    },
    async getCurrentRate() {
      const url = `https://api.binance.com/api/v3/ticker/price?symbol=BNBUSDT`;
      return Axios.get(url);
    },

    async getBalanceSRCX(accountAdress) {
      // const address = await this.getMetamaskAddress();
      // console.log(address);
      // const amount = web3.utils.toWei(value);
      try {
        const balance = await SRCXContract.methods
          .balanceOf(accountAdress)
          .call();
        return balance;
      } catch (e) {
        console.log(e);
        return null;
      }
    },
  },
};
