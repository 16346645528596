import {
  minLength,
  maxLength,
  alphaNum
} from 'vuelidate/lib/validators';

export default {
  validations: {
    bscRecieveAddress: {
      minLength: minLength(42),
      maxLength: maxLength(42),
      alphaNum,
    },
  },
  computed: {
    isBSCRecieveAddressError() {
      return (
        (this.$v.bscRecieveAddress.$dirty && this.$v.bscRecieveAddress.$invalid) ||
        !this.$v.bscRecieveAddress.alphaNum
      );
    },
    BSCRecieveAddressErrorText() {
      if (!this.$v.bscRecieveAddress.alphaNum) {
        return 'Recieve Address must contain only numbers and latin characters';
      }
      if (!this.$v.bscRecieveAddress.minLength || !this.$v.bscRecieveAddress.maxLength) {
        return 'Recieve Address must be exactly 42 characters';
      }
    },
  },
};
