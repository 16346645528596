import {
  minLength,
  maxLength,
  alphaNum,
  helpers
} from 'vuelidate/lib/validators';
const sourceParamLower = 'source';
const sourceParamUpper = 'SOURCE';

const source = (param) => {
  let isValid = false;
  let helperValid = !helpers.req(param);

  helperValid || (
    param.indexOf(sourceParamLower) === 0 ||
    param.indexOf(sourceParamUpper) === 0
  ) ? (isValid = true) : (isValid = false)

  return isValid;
}

export default {
  validations: {
    sourceRecieveAddress: {
      // minLength: minLength(42),
      // maxLength: maxLength(42),
      alphaNum,
      source,
    },
  },
  computed: {
    isSourceRecieveAddressError() {
      return (
        (this.$v.sourceRecieveAddress.$dirty && this.$v.sourceRecieveAddress.$invalid) ||
        !this.$v.sourceRecieveAddress.alphaNum | !this.$v.sourceRecieveAddress.source
      );
    },
    sourceRecieveAddressErrorText() {
      if (!this.$v.sourceRecieveAddress.alphaNum) {
        return 'Recieve Address must contain only numbers and latin characters';
      }
      if (!this.$v.sourceRecieveAddress.source) {
        return 'Recieve Address must be SOURCE token';
      }
      // if (!this.$v.sourceRecieveAddress.minLength || !this.$v.sourceRecieveAddress.maxLength) {
      //   return 'Recieve Address must be exactly 42 characters';
      // }
    },
  },
};